/* 
@font-face {
  font-family: 'PortoRoobert';
  src: url("./assets/fonts/PortoRoobert-Medium.otf"), url("./assets/fonts/PortoRoobert-Bold.otf") ;
} 
@font-face {
  font-family: 'PortoRoobertBold';
  src: url("./assets/fonts/PortoRoobert-Bold.otf") ;
} 
@font-face {
  font-family: 'PortoRoobertSemi';
  src: url("./assets/fonts/PortoRoobert-SemiBold.otf") ;
}  */

@font-face {
  font-family: 'PortoRoobert-Regular';
  src: url('./fonts/PortoRoobert-Regular.ttf');
}
@font-face {
  font-family: 'PortoRoobert-SemiBold';
  src: url('./fonts/PortoRoobert-SemiBold.ttf');
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  font-family: 'PortoRoobert-Regular', Arial, Helvetica, sans-serif;

  color: #192534;
  height: 100%;
}

a p {
  font-family: 'PortoRoobert-SemiBold';
  line-height: normal;
}

a span:hover {
  color: #006fd6;
}

p {
  font-family: 'PortoRoobert-Regular';
}
span {
  font-weight: 200 !important;
}

button {
  font-weight: 300 !important;
}

a {
  font-family: 'PortoRoobert-SemiBold';
}

strong {
  font-family: 'PortoRoobert-SemiBold';
}
#root {
  height: 100%;
  overflow-x: hidden;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  font-family: 'PortoRoobert-Regular', Arial, Helvetica, sans-serif;
}

button,
a {
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

a[disabled] {
  cursor: not-allowed;
}

input,
select,
textarea {
  font-family: inherit;
}

::placeholder {
  color: rgba(0, 0, 0, 0.72);
  opacity: 1;
}

::-ms-input-placeholder {
  font-weight: 350;
  color: rgba(0, 0, 0, 0.72);
}

:focus {
  outline: none;
}

#root {
  scroll-behavior: smooth;
}

.carousel.carousel-slider .control-arrow {
  display: none;
}

.react-multiple-carousel__arrow--right {
  right: 0 !important;
}

.react-multiple-carousel__arrow--left {
  left: 0 !important;
}

.react-multiple-carousel__arrow {
  background-color: #fff !important;
}

.react-multiple-carousel__arrow:hover {
  background-color: #006fd6 !important;
}

.react-multiple-carousel__arrow::before {
  color: #006fd6 !important;
  font-size: 24px !important;
}
.react-multiple-carousel__arrow:hover::before {
  color: #fff !important;
}

.imgFooter {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.imgFooter img {
  width: 100%;
  max-height: 800px;
}

.logoHeader {
  margin-left: 24px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.bordaLogo {
  border-right: 1px solid #1f1f1f;
  margin-left: 8px;
  height: 25px;
  margin-right: -8px;
}
.bordaLogoBranca {
  margin-left: -8px;
  border-right: 1px solid #fff;
  height: 25px;
  margin-right: -1px;
}

.carousel-mkp {
  cursor: pointer;
}

.img-vitrine {
  width: 80% !important;
  min-height: 100% !important;
}

@media (max-width: 750px) {
  .hegth-img {
    min-height: 180px !important;
  }
}

.card-border {
  border-radius: 16px !important;
  cursor: pointer;
  &:hover {
    border: 1px solid #0046c0 !important;
  }
}
.card-border-int {
  border-radius: 12px !important;
}

.carousel .slider-wrapper {
  min-height: 750px;
}

.carousel .slide img {
  height: 100%;
}

.localizadiv .carousel .slider-wrapper {
  min-height: initial;
}

@media (max-width: 767px) {
  .carousel .slider-wrapper {
    height: auto !important;
    min-height: auto !important;
  }
}
